import { useMutation, useQueryClient } from 'react-query';
import { Formik, FormikHelpers } from 'formik';
import ImageIcon from '@mui/icons-material/Image';
import {
    Button,
    Box,
    Grid,
    useTheme,
    CircularProgress,
    Drawer,
    Typography,
    FormControlLabel,
} from '@mui/material';
import FormikTextField from '../../components/Formik/FormikTextField';
import { customValidate } from '../../helpers/validation';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { useEffect, useState } from 'react';
import {
    CreditPackage,
    CreditPackageFormData,
    INITIAL_CREDIT_PACKAGE_FORM_DATA,
} from '../../types/credits';
import { createCreditPackage, updateCreditPackage } from '../../api/creditPackage';
import DrawerHeader from '../../components/Drawer/DrawerHeader';
import DrawerContent from '../../components/Drawer/DrawerContent';
import DrawerFooter from '../../components/Drawer/DrawerFooter';
import { APIError } from '../../axios/axiosVeerlInstance';
import FormikSwitch from '../../components/Formik/FormikSwitch';
import { creditPackageValidationSchema } from '../../validation/creditPackageSchema';
import MediaUpload from '../../components/ModelCreator/MediaUpload/MediaUpload';
import ModelPrice from '../../components/ModelCreator/ModelPrice/ModelPrice';

type CreditPackageCreatorProps = {
    editingCreditPackage: CreditPackage | null;
    isOpen: boolean;
    onClose: () => void;
};

const CreditPackageCreator = ({
    editingCreditPackage,
    isOpen,
    onClose,
}: CreditPackageCreatorProps) => {
    const theme = useTheme();
    const queryClient = useQueryClient();

    const cid = editingCreditPackage?.id;
    const isEditMode = !!cid;

    const setToastState = useSetAtom(toastAtom);

    const [initialFormData, setInitialFormData] = useState<CreditPackageFormData>({
        ...INITIAL_CREDIT_PACKAGE_FORM_DATA,
    });

    useEffect(() => {
        if (isOpen && editingCreditPackage?.id) {
            setInitialFormData({
                name: editingCreditPackage.name,
                description: editingCreditPackage.description,
                amount: editingCreditPackage.amount,
                price: editingCreditPackage.price,
                discount: editingCreditPackage.discount,
                thumbnail: editingCreditPackage.thumbnail,
            });
        } else if (isOpen) {
            setInitialFormData({ ...INITIAL_CREDIT_PACKAGE_FORM_DATA });
        }
    }, [isOpen]);

    const { mutateAsync: mutateCreateCreditPackage, isLoading: isCreatingCreditPackage } =
        useMutation('createCreditPackage', createCreditPackage, {
            onSuccess: () => {
                queryClient.invalidateQueries('creditPackages');
                onClose();
                setToastState({
                    message: 'Credit package created successfully!',
                    severity: 'success',
                });
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        });

    const { mutateAsync: mutateUpdateCreditPackage, isLoading: isUpdatingCreditPackage } =
        useMutation(
            'updateCreditPackage',
            ({
                creditPackageFormData,
                id,
            }: {
                creditPackageFormData: CreditPackageFormData;
                id: string;
            }) => updateCreditPackage(creditPackageFormData, id),
            {
                onSuccess: () => {
                    queryClient.invalidateQueries('creditPackages');
                    onClose();
                    setToastState({
                        message: 'Credit package updated successfully!',
                        severity: 'success',
                    });
                },
                onError: (error: APIError) => {
                    setToastState({
                        message: error?.message,
                        severity: 'error',
                    });
                },
            }
        );

    const onSubmitHandler = async (
        values: CreditPackageFormData,
        helpers: FormikHelpers<CreditPackageFormData>
    ) => {
        if (isEditMode) {
            try {
                await mutateUpdateCreditPackage({ creditPackageFormData: values, id: cid });
            } catch (err) {}
        } else {
            try {
                await mutateCreateCreditPackage(values);
            } catch (err: any) {
                const apiError = err as APIError;
                if (apiError?.message?.includes('exists')) {
                    helpers.setFieldError('name', 'Credit package with this name already exists');
                }
            }
        }
    };

    return (
        <Drawer anchor="right" open={isOpen} onClose={onClose}>
            <DrawerHeader
                onClose={onClose}
                label={
                    <Box component="div" display="flex" alignItems="center" gap="16px">
                        <Typography variant="p1SemiBold">
                            {isEditMode ? 'Edit credit package' : 'Create credit package'}
                        </Typography>
                    </Box>
                }
            />
            <DrawerContent>
                <Formik
                    enableReinitialize
                    initialValues={initialFormData}
                    onSubmit={onSubmitHandler}
                    validate={(values) =>
                        customValidate(values, creditPackageValidationSchema, true)
                    }
                    validateOnBlur={false}
                    validateOnChange={false}
                    validationSchema={creditPackageValidationSchema}>
                    {({ submitForm, values }) => (
                        <Grid
                            item
                            sx={{
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                            <Box
                                component="div"
                                sx={{
                                    borderRight: `1px solid ${theme.palette.borders}`,
                                    width: '430px',
                                    padding: '0px 24px 20px 20px',
                                }}>
                                <Grid
                                    item
                                    xs
                                    style={{
                                        paddingBottom: 0,
                                    }}>
                                    <Grid
                                        container
                                        sx={{
                                            '& > .MuiGrid-item': {
                                                padding: '16px 0',
                                                borderBottom: '1px solid ' + theme.palette.borders,
                                            },
                                            '& > .MuiGrid-item:first-child': {
                                                paddingTop: 0,
                                            },
                                            '& > .MuiGrid-item:last-child': {
                                                borderBottom: 'none',
                                                paddingBottom: 0,
                                            },
                                        }}>
                                        <Grid item xs={12}>
                                            <Grid container spacing="16px">
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        label="Name"
                                                        name="name"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        multiline
                                                        rows={12}
                                                        inputProps={{
                                                            maxLength: 2048,
                                                        }}
                                                        label="Description"
                                                        name="description"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormikTextField
                                                        fullWidth
                                                        label="Amount of credits"
                                                        type="number"
                                                        name="amount"
                                                        variant="outlined"
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <ModelPrice />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MediaUpload
                                                        commonID={cid + ''}
                                                        icon={<ImageIcon />}
                                                        title="Thumbnail"
                                                        availableFormats={['.png']}
                                                        name="thumbnail"
                                                        description="Upload .png file"
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Box>
                            <DrawerFooter>
                                <Button
                                    disabled={isCreatingCreditPackage || isUpdatingCreditPackage}
                                    variant="contained"
                                    color="primary"
                                    onClick={submitForm}>
                                    {!isCreatingCreditPackage && !isUpdatingCreditPackage ? (
                                        'Save'
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </Button>
                            </DrawerFooter>
                        </Grid>
                    )}
                </Formik>
            </DrawerContent>
        </Drawer>
    );
};

export default CreditPackageCreator;
