import { useFormikContext } from 'formik';
import Grid from '@mui/material/Grid';
import CustomDateField from '../CustomDateField/CustomDateField';
import CustomTimeField from '../CustomTimeField/CustomTimeField';
import { isValid } from 'date-fns';

type SelectTimeProps = {
    name: string;
    label: string;
    disabled?: boolean;
    disabledDate?: string;
};

const SelectTime = ({ label, name, disabled, disabledDate }: SelectTimeProps) => {
    const { setFieldValue, values, errors, touched } = useFormikContext<any>();

    const handleDateChange = (date: Date | null) => {
        if (!date) {
            setFieldValue(name, null);
            return;
        }

        if (isValid(date)) setFieldValue(name, date.toISOString());
    };

    const handleTimeChange = (time: Date | null) => {
        if (!time) {
            setFieldValue(name, null);
            return;
        }

        if (isValid(time)) setFieldValue(name, time.toISOString());
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <CustomDateField
                    label={`${label} date`}
                    disabled={disabled}
                    value={values[name]}
                    onChange={handleDateChange}
                    error={errors[name] as string}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <CustomTimeField
                    label={`${label} time`}
                    disabled={disabled}
                    value={values[name]}
                    onChange={handleTimeChange}
                    error={errors[name] as string}
                />
            </Grid>
        </Grid>
    );
};

export default SelectTime;
