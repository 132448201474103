import { AppRoute } from '../types/routes';

import { ReactComponent as DashboardIcon } from '../assets/icons/dashboard.svg';
import { ReactComponent as ModelsIcon } from '../assets/icons/models.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import { ReactComponent as SalesIcon } from '../assets/icons/sales.svg';
import { ReactComponent as ReportIcon } from '../assets/icons/report.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as StarIcon } from '../assets/icons/star.svg';
import { ReactComponent as MintIcon } from '../assets/icons/mint.svg';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ModelCreator from '../views/Models/ModelCreator';
import ModelsView from '../views/Models/ModelsView';
import CollectionsView from '../views/Collections/CollectionsView';
import UsersView from '../views/Users/UsersView';
import UserDetails from '../views/Users/UserDetails';
import TiersSubscriptions from '../views/Tiers/TiersSubscriptions';
import CreditPackagesView from '../views/CreditPackages/CreditPackagesView';

const ROUTES: AppRoute[] = [
    // {
    //     path: '/',
    //     element: <div>Dashboard</div>,
    //     title: 'Dashboard',
    //     icon: <DashboardIcon />,
    //     isMenu: true,
    //     index: true,
    // },
    {
        path: '/collections',
        element: <CollectionsView />,
        title: 'Collections',
        icon: <StarIcon />,
        isMenu: true,
    },
    {
        path: '/models',
        element: <ModelsView />,
        title: 'Models',
        icon: <ModelsIcon />,
        isMenu: true,
    },
    {
        path: '/models/create',
        element: <ModelCreator />,
        title: 'Add model',
    },
    {
        path: '/models/:mid/edit',
        element: <ModelCreator />,
        title: 'Edit model',
    },
    {
        path: '/credits/packages',
        element: <CreditPackagesView />,
        title: 'Credit packages',
        icon: <MonetizationOnIcon style={{ width: '20px' }} />,
        isMenu: true,
    },
    {
        path: '/users',
        element: <UsersView />,
        title: 'Users',
        icon: <UsersIcon />,
        isMenu: true,
    },
    {
        path: '/users/:uid',
        element: <UserDetails />,
        title: 'Edit user',
    },
    // {
    //     path: '/sales',
    //     element: <div>Sales</div>,
    //     title: 'Sales',
    //     icon: <SalesIcon />,
    //     isMenu: true,
    // },
    // {
    //     path: '/report',
    //     element: <div>Report</div>,
    //     title: 'Report',
    //     icon: <ReportIcon />,
    //     isMenu: true,
    // },
    // {
    //     path: '/settings',
    //     element: <div>Settings</div>,
    //     title: 'Settings',
    //     icon: <SettingsIcon />,
    //     isMenu: true,
    // },
    {
        path: '/tiers',
        element: <TiersSubscriptions />,
        title: 'Tiers',
        icon: <MintIcon />,
        isMenu: true,
    },
];

export default ROUTES;
