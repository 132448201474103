import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format, isValid, parse, parseISO } from 'date-fns';
import { Grid, Typography } from '@mui/material';

type CustomDateFieldProps = {
    value: any;
    onChange: (value: Date | null) => void;
    label: string;
    disabled?: boolean;
    error?: string | undefined;
    disablePast?: boolean;
};

const CustomDateField = ({
    value,
    onChange,
    error,
    label,
    disabled,
    disablePast,
}: CustomDateFieldProps) => {
    const dateValue = value
        ? isValid(parseISO(value))
            ? parseISO(value)
            : parse(value, 'dd/MM/yyyy', new Date())
        : null;

    const validDateValue = isValid(dateValue) ? dateValue : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                disabled={disabled}
                label={label}
                disablePast={disablePast}
                value={validDateValue}
                onChange={(newValue) => onChange(newValue)}
                format="dd/MM/yyyy"
            />
            {error ? (
                <Grid
                    item
                    xs={12}
                    sx={{
                        padding: '2px 12px',
                    }}>
                    <Typography variant="p2" color="error">
                        {error}
                    </Typography>
                </Grid>
            ) : null}
        </LocalizationProvider>
    );
};

export default CustomDateField;
