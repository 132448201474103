import yup from '../helpers/yup';
import { DiscountValidationSchema } from './modelSchema';

export const creditPackageValidationSchema = yup.object().shape({
    name: yup.string().required().max(128),
    description: yup.string().optional().max(2048),
    amount: yup
        .number()
        .required('Credits are required')
        .min(1, 'Credits must be at least 1'),
    price: yup
        .number()
        .required('Price is required')
        .min(1, 'Price must be at least 1')
        .max(99999999.99, 'Price must be at most 99999999.99'),
    discount: DiscountValidationSchema.optional(),
    thumbnail: yup.string().optional(),
});
