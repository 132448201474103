import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { getSpecificUser, getUserAssets } from '../../api/user';
import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Tab, Tabs } from '@mui/material';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import UserAvatar from '../../components/UserAvatar/UserAvatar';
import PersonalData from './Tabs/PersonalData';
import PaymentHistory from './Tabs/PaymentHistory';
import UserAssets from './Tabs/UserAssets';
import UserSubscriptions from './Tabs/UserSubscriptions';
import SetCreditsDialog from '../../components/AddUserCreditsDialog/AddUserCreditsDialog';

type UserDetailsTab = {
    label: string;
    value: string;
    actionLabel?: string;
};

const tabs: UserDetailsTab[] = [
    {
        label: 'Personal data',
        value: 'personalData',
    },
    {
        label: 'Payment history',
        value: 'paymentHistory',
    },
    {
        label: 'Assets',
        value: 'assets',
        actionLabel: 'Add Asset',
    },
    {
        label: 'Subscriptions',
        value: 'subscriptions',
        actionLabel: 'Add Subscription',
    },
];

const UserDetails = () => {
    const navigate = useNavigate();
    const { uid } = useParams();

    const [activeTab, setActiveTab] = useState<UserDetailsTab>(tabs[0]);
    const [triggerAction, setTriggerAction] = useState<boolean>();

    const [isSetCreditsOpen, setIsSetCreditsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!uid) {
            navigate('/users');
        }
    }, [uid, navigate]);

    const { data: userData, isFetched: isFetchedUser } = useQuery(
        ['user', uid],
        () => getSpecificUser(uid),
        {
            enabled: !!uid,
            onError: (error: AxiosError) => {
                if (error.response?.status === 404) {
                    navigate('/users');
                }
            },
        }
    );

    const { data: userAssets } = useQuery(['userAssets', uid], () => getUserAssets(uid));

    const onTabChangeHandler = (event: React.ChangeEvent<{}>, newValue: string) => {
        const newTab = tabs.find((tab) => tab.value === newValue);
        if (newTab) {
            setActiveTab(newTab);
            setTriggerAction(undefined);
        }
    };

    const handleOpenSetCredits = (userId: string) => {
        setIsSetCreditsOpen(true);
    };

    const handleCloseSetCredits = () => {
        setIsSetCreditsOpen(false);
    };

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                height: '100%',
                alignItems: userData?.id ? 'flex-start' : 'center',
                justifyContent: 'center',
                padding: activeTab.value === 'personalData' ? '64px 16px' : '64px 24px',
            }}>
            {userData?.id ? (
                <Box
                    component="div"
                    maxWidth={activeTab.value === 'personalData' ? '920px' : '100%'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}>
                    <Box
                        component="div"
                        sx={{
                            padding: '0 12px',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}>
                        <UserAvatar
                            firstName={userData.firstName}
                            tierSubscription={userAssets?.subscriptions?.tier?.reference?.tier}
                            dateLastActive={userData.dateLastActive}
                            credits={userData.credits}
                            onSetCredits={() => handleOpenSetCredits(userData.id)}
                        />
                        {activeTab.actionLabel ? (
                            <Button
                                onClick={() => setTriggerAction((prev) => !prev)}
                                variant="contained"
                                color="primary"
                                endIcon={<PlusIcon />}>
                                {activeTab.actionLabel}
                            </Button>
                        ) : null}
                    </Box>
                    <Tabs
                        value={activeTab.value}
                        style={{ marginBottom: 32, marginTop: 32 }}
                        onChange={onTabChangeHandler}>
                        {tabs.map((tab) => (
                            <Tab key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </Tabs>
                    {activeTab.value === 'personalData' ? (
                        <PersonalData userData={userData} />
                    ) : activeTab.value === 'paymentHistory' ? (
                        <PaymentHistory userID={userData.id} />
                    ) : activeTab.value === 'assets' ? (
                        <UserAssets
                            userID={userData.id}
                            userName={userData.firstName}
                            triggerAction={triggerAction}
                        />
                    ) : (
                        <UserSubscriptions
                            userID={userData.id}
                            userName={userData.firstName}
                            triggerAction={triggerAction}
                        />
                    )}
                </Box>
            ) : (
                <CircularProgress size={45} />
            )}
            {userData ? (
                <SetCreditsDialog
                    open={isSetCreditsOpen}
                    onClose={handleCloseSetCredits}
                    userId={userData.id}
                    currentCredits={userData.credits}
                />
            ) : null}
        </Box>
    );
};

export default UserDetails;
