import React, { useState, useRef, useEffect } from 'react';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import FormikAutocompleteSelect from '../Formik/FormikAutocompleteSelect';

interface TTSSelectProps {
    voices: Record<string, string>;
    disabled?: boolean;
}

const TTSSelect = ({ voices, disabled }: TTSSelectProps) => {
    const [currentPlaying, setCurrentPlaying] = useState<string | null>(null);
    const audioRefs = useRef<Record<string, HTMLAudioElement | null>>({});

    const handlePlayStop = (voiceId: string, event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        const audio = audioRefs.current[voiceId];

        if (audio) {
            if (currentPlaying === voiceId) {
                audio.pause();
                setCurrentPlaying(null);
            } else {
                if (currentPlaying && audioRefs.current[currentPlaying]) {
                    audioRefs.current[currentPlaying]?.pause();
                }
                audio.play();
                setCurrentPlaying(voiceId);
            }
        }
    };

    useEffect(() => {
        const handleAudioEnded = (voiceId: string) => {
            if (currentPlaying === voiceId) {
                setCurrentPlaying(null);
            }
        };

        Object.keys(audioRefs.current).forEach((voiceId) => {
            const audio = audioRefs.current[voiceId];
            if (audio) {
                audio.addEventListener('ended', () => handleAudioEnded(voiceId));
            }
        });

        return () => {
            Object.keys(audioRefs.current).forEach((voiceId) => {
                const audio = audioRefs.current[voiceId];
                if (audio) {
                    audio.removeEventListener('ended', () => handleAudioEnded(voiceId));
                }
            });
        };
    }, [currentPlaying]);

    return (
        <Grid item xs={12}>
            <FormikAutocompleteSelect
                label="TTS Voice"
                name="assistant.voice"
                disabled={disabled}
                fullWidth
                items={Object.entries(voices).map(([voiceName, audioBase64]) => ({
                    id: voiceName,
                    name: voiceName,
                    render: (
                        <Box
                            component="div"
                            display="flex"
                            alignItems="center"
                            width="100%"
                            justifyContent="space-between"
                            key={voiceName}>
                            <Typography>{voiceName}</Typography>
                            <Box component="div">
                                <IconButton
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                        handlePlayStop(voiceName, event)
                                    }
                                    size="small">
                                    {currentPlaying === voiceName ? (
                                        <StopIcon />
                                    ) : (
                                        <PlayArrowIcon />
                                    )}
                                </IconButton>
                                <audio
                                    ref={(el) => (audioRefs.current[voiceName] = el)}
                                    src={`data:audio/wav;base64,${audioBase64}`}
                                />
                            </Box>
                        </Box>
                    ),
                }))}
                valueKey="id"
                labelKey="name"
                renderKey="render"
            />
        </Grid>
    );
};

export default TTSSelect;
