import { createTheme } from '@mui/material/styles';

declare module '@mui/material/Chip' {
    interface ChipPropsVariantOverrides {
        primary: true;
        secondary: true;
        primaryOutlined: true;
    }
}

declare module '@mui/material/styles' {
    interface Components {
        MuiDataGrid?: {
            styleOverrides?: {
                root?: {
                    [key: string]: any;
                };
            };
        };
    }

    interface Palette {
        black84: string;
        deepPurple: string;
        black54: string;
        disabledGray: string;
        borders: string;
        backgorundDarker: string;
        backgroundLighter: string;
        white: string;
        accentMagenta: string;
        pink: string;
        accentVividBlue: string;
        disabledVividBlue: string;
        lightBlue: string;
        confirmationGreen: string;
        alert: string;
        accentMagenta2: string;
        accentMagenta3: string;
        purpleLight2: string;
        lightYellow: string;
        warningYellow: string;
        test: string;
    }

    interface PaletteOptions {
        black84?: string;
        deepPurple?: string;
        black54?: string;
        disabledGray?: string;
        borders?: string;
        backgorundDarker?: string;
        backgroundLighter?: string;
        white?: string;
        accentMagenta?: string;
        pink?: string;
        accentVividBlue?: string;
        disabledVividBlue?: string;
        lightBlue?: string;
        confirmationGreen?: string;
        alert?: string;
        accentMagenta2?: string;
        accentMagenta3?: string;
        purpleLight2?: string;
        lightYellow?: string;
        warningYellow?: string;
        test?: string;
    }

    interface TypographyVariants {
        p1: true;
        p1SemiBold: true;
        p1Underlined: true;
        p2: true;
        p2SemiBold: true;
        p2Underlined: true;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        p1: true;
        p1SemiBold: true;
        p1Underlined: true;
        p2: true;
        p2SemiBold: true;
        p2Underlined: true;
    }
}

let theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        text: {
            primary: '#000000',
            disabled: '#BCBCBC',
        },
        error: {
            main: '#F33951',
        },
        black84: '#292929',
        deepPurple: '#0C0521',
        black54: '#757575',
        disabledGray: '#BCBCBC',
        borders: '#DEE0E3',
        backgorundDarker: '#E9E9E9',
        backgroundLighter: '#F3F3F3',
        white: '#FFFFFF',
        pink: '#FFF2F7',
        accentVividBlue: '#2E28FF',
        disabledVividBlue: '#9794FF',
        lightBlue: '#EBEAFF',
        confirmationGreen: '#06BE7E',
        alert: '#F33951',
        accentMagenta: '#FF0B6D',
        accentMagenta2: '#F37FAD',
        accentMagenta3: '#FAB8D2',
        lightYellow: '#FFF4D7',
        warningYellow: '#E4AD19',
        purpleLight2: '#8A83C1',
    },
});
theme = createTheme({
    palette: {
        primary: {
            main: '#000000',
        },
        text: {
            primary: '#000000',
            disabled: '#BCBCBC',
        },
        error: {
            main: '#F33951',
        },
        black84: '#292929',
        deepPurple: '#0C0521',
        black54: '#757575',
        disabledGray: '#BCBCBC',
        borders: '#DEE0E3',
        backgorundDarker: '#E9E9E9',
        backgroundLighter: '#F3F3F3',
        white: '#FFFFFF',
        pink: '#FFF2F7',
        accentVividBlue: '#2E28FF',
        disabledVividBlue: '#9794FF',
        lightBlue: '#EBEAFF',
        confirmationGreen: '#06BE7E',
        alert: '#F33951',
        accentMagenta: '#FF0B6D',
        accentMagenta2: '#F37FAD',
        accentMagenta3: '#FAB8D2',
        lightYellow: '#FFF4D7',
        warningYellow: '#E4AD19',
        purpleLight2: '#8A83C1',
    },
    typography: {
        fontFamily: [
            'Inter',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        h1: {
            color: '#000000',
            fontWeight: 600,
            fontSize: '40px',
            lineHeight: '56px',
        },
        h2: {
            color: '#000000',
            fontWeight: 600,
            fontSize: '32px',
            lineHeight: '40px',
        },
        h3: {
            color: '#000000',
            fontWeight: 600,
            fontSize: '24px',
            lineHeight: '32px',
        },
        h4: {
            color: '#000000',
            fontWeight: 600,
            fontSize: '20px',
            lineHeight: '28px',
        },
        h5: {
            color: '#000000',
            fontWeight: 600,
            fontSize: '18px',
            lineHeight: '26px',
        },
        h6: {
            color: '#000000',
            fontWeight: 400,
            fontSize: '18px',
            lineHeight: '26px',
        },
    },
    components: {
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .Mui-disabled': {
                        backgroundColor: theme.palette.backgroundLighter,
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, Arial, sans-serif',
                    fontWeight: 600,
                    color: '#000000',
                    fontSize: '16px',
                    lineHeight: '24px',
                },
            },
        },
        MuiDialogContentText: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, Arial, sans-serif',
                    color: '#000000',
                    fontSize: '16px',
                    lineHeight: '24px',
                    textAlign: 'center',
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    color: theme.palette.accentVividBlue,
                    '.MuiTab-root': {
                        color: theme.palette.black54,
                        textTransform: 'none',
                        '&.Mui-selected': {
                            color: theme.palette.accentVividBlue,
                        },
                    },
                },
                indicator: {
                    backgroundColor: theme.palette.accentVividBlue,
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus .MuiDataGrid-cellValue': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
                        outline: 'none !important',
                    },
                    '& .MuiDataGrid-row, & .MuiDataGrid-row .Mui-selected, & .MuiDataGrid-row .Mui-selected:hover':
                        {
                            backgroundColor: theme.palette.backgroundLighter,
                            '&:hover': {
                                backgroundColor: theme.palette.lightBlue,
                                cursor: 'pointer',
                            },
                        },
                    '& .MuiDataGrid-row.Mui-selected': {
                        backgroundColor: theme.palette.lightBlue,
                    },
                    '& .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: theme.palette.lightBlue,
                    },
                    '& .Mui-selected': {
                        backgroundColor: theme.palette.backgroundLighter,
                    },
                    '& .MuiDataGrid-footerContainer': {
                        borderTop: 'none',
                    },
                    '& .MuiDataGrid-row': {
                        marginBottom: '4px',
                        borderRadius: '4px',
                    },
                    '& .MuiDataGrid-virtualScrollerContent': {
                        marginBottom: '-4px',
                    },
                    '& .MuiDataGrid-row .MuiDataGrid-cell:first-of-type': {
                        borderTopLeftRadius: '4px',
                        borderBottomLeftRadius: '4px',
                    },
                    '& .MuiDataGrid-row .MuiDataGrid-cell:last-of-type': {
                        borderTopRightRadius: '4px',
                        borderBottomRightRadius: '4px',
                    },
                    '& .stickyColumnCell': {
                        position: 'sticky !important',
                        opacity: '1 !important',
                        right: '0 !important',
                        zIndex: '2 !important',
                        background: theme.palette.backgorundDarker,
                        borderRadius: '4px',
                        width: '80px !important',
                    },
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    '& .MuiChip-label': {
                        paddingLeft: '8px',
                        paddingRight: '8px',
                    },
                },
            },
            variants: [
                {
                    props: { variant: 'primary' },
                    style: {
                        height: '20px',
                        backgroundColor: theme.palette.accentVividBlue,
                        color: theme.palette.white,
                        borderRadius: '24px',
                    },
                },
                {
                    props: { variant: 'primaryOutlined' },
                    style: {
                        height: '20px',
                        border: `1px solid ${theme.palette.accentVividBlue}`,
                        backgroundColor: 'transparent',
                        color: theme.palette.accentVividBlue,
                        borderRadius: '24px',
                    },
                },
                {
                    props: { variant: 'secondary' },
                    style: {
                        height: '20px',
                        backgroundColor: theme.palette.white,
                        color: theme.palette.black84,
                        borderRadius: '24px',
                    },
                },
                {
                    props: { variant: 'filled' },
                    style: {
                        height: '20px',
                        backgroundColor: theme.palette.lightBlue,
                        color: theme.palette.accentVividBlue,
                        borderRadius: '24px',
                    },
                },
            ],
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    backgroundColor: theme.palette.white,
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    '&.Mui-selected, &:active, &:focus': {
                        backgroundColor: theme.palette.accentVividBlue,
                        color: 'white',
                    },
                    '&.Mui-selected:hover': {
                        backgroundColor: theme.palette.accentVividBlue,
                    },
                    '&:hover': {
                        backgroundColor: theme.palette.lightBlue,
                    },
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: theme.palette.black84,
                    '&.Mui-checked': {
                        color: theme.palette.accentVividBlue,
                    },
                    '&.Mui-disabled': {
                        color: theme.palette.disabledVividBlue,
                        '&.Mui-checked': {
                            color: theme.palette.disabledVividBlue,
                        },
                    },
                },
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: theme.palette.black84,
                    '&.Mui-checked': {
                        color: theme.palette.accentVividBlue,
                    },
                    '&.Mui-disabled': {
                        color: theme.palette.disabledVividBlue,
                        '&.Mui-checked': {
                            color: theme.palette.disabledVividBlue,
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '&.Mui-disabled': {
                        backgroundColor: '#F3F3F3 !important',
                    },
                    '&.Mui-disabled:before': {
                        borderBottom: '0 !important'
                    }
                },
            },
        },
        MuiSwitch: {
            styleOverrides: {
                track: {
                    backgroundColor: theme.palette.black54,
                },
                thumb: {
                    backgroundColor: theme.palette.white,
                },
                switchBase: {
                    color: theme.palette.accentVividBlue,
                    '&.Mui-checked': {
                        color: theme.palette.accentVividBlue,
                        '+ .MuiSwitch-track': {
                            backgroundColor: theme.palette.lightBlue,
                        },
                        '& .MuiSwitch-thumb': {
                            backgroundColor: theme.palette.accentVividBlue,
                        },
                    },
                },
            },
        },
        MuiCircularProgress: {
            styleOverrides: {
                colorPrimary: {
                    color: theme.palette.accentVividBlue,
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: '100%',
                    [theme.breakpoints.up('sm')]: {
                        width: '430px',
                    },
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                standard: {
                    backgroundColor: theme.palette.lightBlue,
                    color: theme.palette.accentVividBlue,
                    borderRadius: '24px',
                    padding: '0px 8px',
                    height: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    maxWidth: '450px',
                    maxHeight: 'auto',
                    height: 'auto',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    fontFamily: 'Inter, Arial, sans-serif',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    '& .MuiAlert-message': {
                        overflow: 'visible',
                        padding: 0,
                        display: 'flex',
                        alignSelf: 'center',
                    },
                    '& .MuiAlert-action': {
                        alignSelf: 'center',
                        paddingBottom: '4px',
                    },
                },
            },
            variants: [
                {
                    props: { severity: 'success' },
                    style: {
                        backgroundColor: '#E7F9F3',
                        color: '#06BE7E',
                        '& .MuiAlert-icon': {
                            color: '#06BE7E',
                            alignSelf: 'center',
                        },
                    },
                },
                {
                    props: { severity: 'error' },
                    style: {
                        backgroundColor: '#FFF2F7',
                        color: '#F33951',
                        '& .MuiAlert-icon': {
                            color: '#F33951',
                        },
                    },
                },
                {
                    props: { severity: 'info' },
                    style: {
                        backgroundColor: '#FFF4D7',
                        color: '#E4AD19',
                        '& .MuiAlert-icon': {
                            color: '#E4AD19',
                        },
                    },
                },
            ],
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Inter, Arial, sans-serif',
                    fontWeight: 600,
                    fontSize: '14px',
                    color: '#000000',
                    lineHeight: '20px',
                    textTransform: 'none',
                    height: '48px',
                    padding: '14px 40px',
                    '&.MuiButton-sizeSmall': {
                        height: '40px',
                        padding: '10px 16px',
                    },
                },
                containedPrimary: {
                    backgroundColor: theme.palette.accentVividBlue,
                    color: '#ffffff',
                    '&:hover': {
                        backgroundColor: '#2824CB',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#9794FF',
                        color: '#ffffff',
                        cursor: 'not-allowed',
                    },
                },
                outlinedSecondary: {
                    color: theme.palette.accentVividBlue,
                    borderColor: theme.palette.accentVividBlue,
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: theme.palette.lightBlue,
                        borderColor: '#2824CB',
                    },
                    '&.Mui-disabled': {
                        borderColor: '#BCBCBC',
                        color: '#BCBCBC',
                    },
                },
                outlinedInfo: {
                    color: theme.palette.black54,
                    borderColor: theme.palette.borders,
                    backgroundColor: '#ffffff',
                    '&:hover': {
                        backgroundColor: theme.palette.white,
                        borderColor: theme.palette.borders,
                    },
                    '&.Mui-disabled': {
                        borderColor: theme.palette.borders,
                    },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    '&.Mui-error': {
                        marginLeft: '11px',
                    },
                },
            },
        },
        MuiTypography: {
            variants: [
                {
                    props: { variant: 'p1SemiBold' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        fontWeight: 600,
                        color: '#000000',
                        fontSize: '16px',
                        lineHeight: '24px',
                    },
                },
                {
                    props: { variant: 'p1' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        fontSize: '16px',
                        color: '#000000',
                        lineHeight: '24px',
                    },
                },
                {
                    props: { variant: 'p1Underlined' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        textDecoration: 'underline',
                        fontSize: '16px',
                        color: '#000000',
                        lineHeight: '24px',
                    },
                },
                {
                    props: { variant: 'p2SemiBold' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        fontWeight: 600,
                        fontSize: '14px',
                        color: '#000000',
                        lineHeight: '20px',
                    },
                },
                {
                    props: { variant: 'p2' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        fontSize: '14px',
                        color: '#000000',
                        lineHeight: '20px',
                    },
                },
                {
                    props: { variant: 'p2Underlined' },
                    style: {
                        fontFamily: 'Inter, Arial, sans-serif',
                        textDecoration: 'underline',
                        fontSize: '14px',
                        color: '#000000',
                        lineHeight: '20px',
                    },
                },
            ],
        },
    },
});

export default theme;
