import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { format, isValid, parse, parseISO } from 'date-fns';

type CustomTimeFieldProps = {
    value: any;
    onChange: (value: Date | null) => void;
    label: string;
    disabled?: boolean;
    error?: string | undefined;
};

const CustomTimeField = ({ value, onChange, label, disabled }: CustomTimeFieldProps) => {
    const timeValue = value
        ? isValid(parseISO(value))
            ? parseISO(value)
            : parse(value, 'HH:mm', new Date())
        : null;

    const validTimeValue =
        timeValue && isValid(timeValue)
            ? parse(format(timeValue, 'HH:mm'), 'HH:mm', new Date())
            : null;

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
                disabled={disabled}
                label={label}
                value={validTimeValue}
                onChange={(newValue) => onChange(newValue)}
                ampm={false}
            />
        </LocalizationProvider>
    );
};

export default CustomTimeField;
