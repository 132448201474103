import { AxiosError } from 'axios';
import axiosVeerlInstance from '../axios/axiosVeerlInstance';
import { handleAxiosError } from '../helpers/axios';
import { serializeFilters } from '../helpers/filters';
import { CreditPackage, CreditPackageFormData } from '../types/credits';
import { BaseFilters } from '../types/table';

export type GetCreditPackagesResponse = {
    creditPackages: CreditPackage[];
    totalResults: number;
};

export const getCreditPackages = async (
    filters?: BaseFilters
): Promise<GetCreditPackagesResponse | void> => {
    let queryParams = filters ? serializeFilters(filters) : '';
    queryParams += '&type=adminpanel';

    const url = queryParams ? `/credit/packages?${queryParams}` : '/credit/packages';

    return await axiosVeerlInstance
        .get<GetCreditPackagesResponse>(url)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }

            return {
                creditPackages: [],
                totalResults: 0,
            };
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const createCreditPackage = async (
    creditPackageFormData: CreditPackageFormData
): Promise<CreditPackage | undefined | void> => {
    return await axiosVeerlInstance
        .post<CreditPackage>(`/credit/package`, creditPackageFormData)
        .then((response) => {
            if (response.status === 201) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const updateCreditPackage = async (
    creditPackageFormData: CreditPackageFormData,
    id: string
): Promise<CreditPackage | undefined | void> => {
    return await axiosVeerlInstance
        .put<CreditPackage>(`/credit/package/` + id, creditPackageFormData)
        .then((response) => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch((error: AxiosError) => {
            handleAxiosError(error as AxiosError);
        });
};

export const deleteCreditPackage = async (id: string) => {
    return await axiosVeerlInstance.delete(`/credit/package/${id}`).catch((error: AxiosError) => {
        handleAxiosError(error as AxiosError);
    });
};
