import {
    DataGrid,
    DataGridProps,
    GridPaginationModel,
    GridSortModel,
    GridColDef,
    GridColumnVisibilityModel,
} from '@mui/x-data-grid';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useState, useMemo, useEffect } from 'react';
import { BaseFilters } from '../../types/table';
import { BASE_PAGE_SIZE_OPTIONS, MIN_ROW_HEIGHT, RowProperties } from '../../helpers/datagrid';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-row': {
        height: '56px !important',
    },
}));

type CustomDataGridProps = DataGridProps & {
    height: string;
    onFiltersChange?: (filters: BaseFilters) => void;
    filters?: BaseFilters;
    scrollbar?: boolean;
    rowProperties: RowProperties;
    tableName: string;
    dataProcess?: 'server' | 'client';
};

const CustomDataGrid = (props: CustomDataGridProps) => {
    const {
        height,
        filters,
        dataProcess = 'server',
        tableName,
        rowProperties,
        rowSelectionModel,
        scrollbar,
        onFiltersChange,
        onRowClick,
        columns: initialColumns,
        ...restProps
    } = props;

    const [columns, setColumns] = useState<GridColDef[]>([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
        {}
    );

    useEffect(() => {
        const storedWidths = localStorage.getItem(`${tableName}-columnWidths`);
        const columnWidths = storedWidths ? JSON.parse(storedWidths) : {};

        const storedVisibility = localStorage.getItem(`${tableName}-columnVisibility`);
        const visibilityModel = storedVisibility ? JSON.parse(storedVisibility) : {};

        const updatedColumns = initialColumns.map((col) => ({
            ...col,
            width: columnWidths[col.field] || col.width,
            hide:
                visibilityModel[col.field] === false ? false : visibilityModel[col.field] || false,
            resizable: true,
        }));

        setColumns(updatedColumns);
        setColumnVisibilityModel(visibilityModel);
    }, [initialColumns, tableName]);

    const onSortChange = (sortModel: GridSortModel) => {
        if (onFiltersChange && filters) {
            onFiltersChange({
                ...filters,
                page: 1,
                sort: sortModel.length
                    ? {
                          field: sortModel[0].field,
                          order: sortModel[0].sort === 'desc' ? 'desc' : 'asc',
                      }
                    : undefined,
            });
        }
    };

    const onPaginationChange = (paginationModel: GridPaginationModel, details: any) => {
        if (onFiltersChange && filters) {
            if (filters.pageSize !== paginationModel.pageSize) {
                localStorage.setItem(`${tableName}.pageSize`, paginationModel.pageSize.toString());
            }
            onFiltersChange({
                ...filters,
                page: paginationModel.page + 1,
                pageSize: paginationModel.pageSize,
            });
        }
    };

    const handleColumnResize = (params: { colDef: GridColDef; width: number }) => {
        const newColumnWidths = {
            ...JSON.parse(localStorage.getItem(`${tableName}-columnWidths`) || '{}'),
            [params.colDef.field]: params.width,
        };
        localStorage.setItem(`${tableName}-columnWidths`, JSON.stringify(newColumnWidths));
    };

    const handleColumnVisibilityModelChange = (newVisibilityModel: GridColumnVisibilityModel) => {
        setColumnVisibilityModel(newVisibilityModel);
        localStorage.setItem(`${tableName}-columnVisibility`, JSON.stringify(newVisibilityModel));
    };

    return (
        <Box
            component="div"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: height,
            }}>
            <StyledDataGrid
                {...restProps}
                columns={columns}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
                onSortModelChange={onSortChange}
                rowHeight={
                    !filters
                        ? rowProperties.rowHeight
                        : filters.pageSize === rowProperties.pageSize
                          ? rowProperties.rowHeight
                          : MIN_ROW_HEIGHT
                }
                sortingMode={dataProcess}
                filterMode={dataProcess}
                paginationMode={dataProcess}
                pageSizeOptions={Array.from(
                    new Set(BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize))
                ).sort((a, b) => a - b)}
                autoPageSize={false}
                disableVirtualization
                disableRowSelectionOnClick
                initialState={
                    filters && dataProcess === 'server'
                        ? {
                              pagination: {
                                  paginationModel: {
                                      page: filters.page - 1,
                                      pageSize: filters.pageSize,
                                  },
                              },
                          }
                        : {
                              pagination: {
                                  paginationModel: {
                                      pageSize: rowProperties.pageSize,
                                  },
                              },
                          }
                }
                paginationModel={
                    filters && dataProcess === 'server'
                        ? {
                              page: filters.page - 1,
                              pageSize: filters.pageSize,
                          }
                        : undefined
                }
                onPaginationModelChange={onPaginationChange}
                onColumnWidthChange={handleColumnResize}
            />
        </Box>
    );
};

export default CustomDataGrid;
