import { useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import {
    ArchiveModelsMutation,
    MODELS_FILTERS_INITIAL,
    Model,
    ModelsFilters,
    PublishModelsMutation,
} from '../../types/model';

import { ReactComponent as FiltersIcon } from '../../assets/icons/filters.svg';
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';
import { ReactComponent as CloseX } from '../../assets/icons/close-x.svg';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    archiveModel,
    archiveModels,
    deleteModel,
    getModels,
    publishModels,
    retoreModel,
} from '../../api/model';
import { MODELS_TABLE_COLUMNS } from '../../const/modelsTableColumns';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import ModelsFilterDrawer from '../../components/Models/ModelsFilterDrawer/ModelsFilterDrawer';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomChip from '../../components/CustomChip/CustomChip';
import { countSelectedFilters } from '../../helpers/filters';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../helpers/datagrid';
import { APIError } from '../../axios/axiosVeerlInstance';
import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid';
import ModelActions from '../../components/Models/ModelActions/ModelActions';

export type ModelTabs = 'active' | 'archived';

const ModelsView = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const theme = useTheme();
    const setToastState = useSetAtom(toastAtom);
    const rowProperties = calculateRowHeightAndPageSize(280, true);
    const savedPageSize = localStorage.getItem('models.pageSize');

    const location = useLocation();
    const [searchParams] = useSearchParams();

    const queryTab = searchParams.get('tab');
    const { collection } = location.state || {};
    const { modelsFilters } = location.state || {};
    const { collectionsFilters } = location.state || {};
    const { referrer } = location.state || [];

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const [filters, setFilters] = useState<ModelsFilters>(
        modelsFilters || {
            ...MODELS_FILTERS_INITIAL,
            pageSize:
                savedPageSize &&
                +savedPageSize &&
                BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                    ? +savedPageSize
                    : rowProperties.pageSize,
            archived: queryTab === 'archived' ? 'true' : 'false',
            sort: {
                field: 'dateUpdate',
                order: 'desc',
            },
            collection: {
                id: collection?.id ? [collection?.id] : [],
            },
        }
    );
    const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
    const [currentTab, setCurrentTab] = useState<ModelTabs>((queryTab as ModelTabs) || 'active');

    const [modelToArchive, setModelToArchive] = useState<Model | null>(null);
    const [modelToDelete, setModelToDelete] = useState<Model | null>(null);
    const [selectedModels, setSelectedModels] = useState<GridRowSelectionModel>([]);

    const { data: modelsResponse, isFetching } = useQuery(['models', filters], () =>
        getModels(filters)
    );

    const archiveMutation = useMutation(archiveModel, {
        onSuccess: () => {
            setToastState({
                message: 'Model archived successfully!',
                severity: 'success',
            });
            setModelToArchive(null);
            queryClient.invalidateQueries('models');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const restoreMutation = useMutation(retoreModel, {
        onSuccess: () => {
            setToastState({
                message: 'Model restored successfully!',
                severity: 'success',
            });
            setModelToArchive(null);
            queryClient.invalidateQueries('models');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const archiveModelsMutation = useMutation(
        (data: ArchiveModelsMutation) => archiveModels(data.modelIds, data.archive),
        {
            onSuccess: () => {
                setToastState({
                    message: `Models ${currentTab === 'active' ? 'archived' : 'restored'} successfully!`,
                    severity: 'success',
                });
                setSelectedModels([]);
                queryClient.invalidateQueries('models');
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const publishModelsMutation = useMutation(
        (data: PublishModelsMutation) => publishModels(data.modelIds, data.publish),
        {
            onSuccess: () => {
                setToastState({
                    message: `Models updated successfully!`,
                    severity: 'success',
                });
                setSelectedModels([]);
                queryClient.invalidateQueries('models');
            },
            onError: (error: APIError) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const deleteMutation = useMutation(deleteModel, {
        onSuccess: () => {
            setToastState({
                message: 'Model deleted successfully!',
                severity: 'success',
            });
            setModelToDelete(null);
            queryClient.invalidateQueries('models');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const onFilterSubmit = (values: ModelsFilters) => {
        setFilters({
            ...values,
            page: 1,
        });
    };

    const numberOfSelectedFilters = countSelectedFilters(filters);

    const resetFilters = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        setFilters({
            ...MODELS_FILTERS_INITIAL,
            search: filters.search,
            page: 1,
        });
    };

    const onArchiveModel = () => {
        if (!modelToArchive) return;

        if (!modelToArchive?.archived) {
            archiveMutation.mutate(modelToArchive.id);
        } else {
            restoreMutation.mutate(modelToArchive.id);
        }
    };

    const onDeleteModel = () => {
        if (!modelToDelete) return;

        deleteMutation.mutate(modelToDelete.id);
    };

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: 'active' | 'archived') => {
        setCurrentTab(newValue);
        setFilters({
            ...filters,
            page: 1,
            archived: newValue === 'archived' ? 'true' : 'false',
        });

        navigate(`?tab=${newValue}`, {
            state: {
                referrer: referrer,
                collectionsFilters,
                modelsFilters: filters,
            },
        });
    };

    const onArchiveModels = (archive: boolean) => {
        if (selectedModels.length > 0) {
            archiveModelsMutation.mutate({
                modelIds: selectedModels as string[],
                archive,
            });
        }
    };

    const onPublishModels = (publish: boolean) => {
        if (selectedModels.length > 0) {
            publishModelsMutation.mutate({
                modelIds: selectedModels as string[],
                publish,
            });
        }
    };

    const modelsData = modelsResponse?.models || [];
    const newReferrer =
        referrer?.length && !referrer.includes('/models') ? [...referrer, '/models'] : ['/models'];

    const rowCountRef = useRef(modelsResponse?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (modelsResponse?.totalResults !== undefined) {
            rowCountRef.current = modelsResponse.totalResults;
        }
        return rowCountRef.current;
    }, [modelsResponse?.totalResults]);

    return (
        <Box component="div" flexDirection="column">
            <ModelsFilterDrawer
                filters={filters}
                onSave={onFilterSubmit}
                isOpen={openFiltersDrawer}
                onClose={() => setOpenFiltersDrawer(false)}
            />
            <Grid
                item
                xs={12}
                sx={{
                    padding: '24px 20px',
                }}>
                <Grid container gap={isSmallScreen ? '16px' : 0}>
                    <Grid item xs={isSmallScreen ? 12 : 4}>
                        <CustomTextField
                            fullWidth
                            name="search"
                            label="Search"
                            size="small"
                            value={filters.search}
                            onChange={(e: any) =>
                                setFilters({ ...filters, search: e.target.value, page: 1 })
                            }
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 8}>
                        <Box
                            component="div"
                            sx={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '16px',
                            }}>
                            <ModelActions
                                disabled={selectedModels.length === 0}
                                currentTab={currentTab}
                                onArchive={() => onArchiveModels(true)}
                                onUnarchive={() => onArchiveModels(false)}
                                onPublish={() => {
                                    onPublishModels(true);
                                }}
                                onUnpublish={() => {
                                    onPublishModels(false);
                                }}
                            />
                            <Button
                                variant="outlined"
                                color="info"
                                endIcon={!isMediumScreen ? <FiltersIcon /> : null}
                                onClick={() => setOpenFiltersDrawer(true)}
                                sx={{
                                    textTransform: 'none',
                                    width: !isMediumScreen
                                        ? numberOfSelectedFilters > 0
                                            ? '160px'
                                            : '120px'
                                        : 'auto',
                                }}>
                                {!isMediumScreen ? 'Filters' : <FiltersIcon />}
                                {numberOfSelectedFilters > 0 ? (
                                    <CustomChip
                                        disabletooltip
                                        variant="filled"
                                        sx={{
                                            marginLeft: '4px',
                                            overflow: 'visible',
                                        }}
                                        onClick={resetFilters}
                                        label={
                                            <Box
                                                component="div"
                                                gap="8px"
                                                display="flex"
                                                alignItems="center">
                                                <Typography
                                                    variant="p2"
                                                    color={theme.palette.accentVividBlue}>
                                                    {numberOfSelectedFilters}
                                                </Typography>
                                                <CloseX />
                                            </Box>
                                        }
                                    />
                                ) : null}
                            </Button>
                            <Button
                                onClick={() =>
                                    navigate('/models/create', {
                                        state: {
                                            referrer: newReferrer,
                                            collectionsFilters,
                                            collection: collection,
                                            modelsFilters: filters,
                                        },
                                    })
                                }
                                variant="contained"
                                color="primary"
                                endIcon={!isMediumScreen ? <PlusIcon /> : null}>
                                {!isMediumScreen ? 'Add Model' : <PlusIcon />}
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                gap="16px"
                sx={{
                    padding: '16px 20px',
                }}>
                <Grid item xs={12}>
                    <Tabs value={currentTab} onChange={handleTabChange}>
                        <Tab label="Active" value="active" />
                        <Tab label="Archived" value="archived" />
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <CustomDataGrid
                        height="calc(100vh - 268px)"
                        scrollbar
                        tableName="models"
                        disableColumnFilter
                        checkboxSelection
                        rowProperties={rowProperties}
                        rows={modelsData}
                        onCellClick={(params) => {
                            if (params.field !== '__check__') {
                                navigate(`/models/${params.row.id}/edit`, {
                                    state: {
                                        collectionsFilters,
                                        modelsFilters: filters,
                                        referrer: newReferrer,
                                    },
                                });
                            }
                        }}
                        columns={MODELS_TABLE_COLUMNS({
                            currentTab: currentTab,
                            onEdit: (row) =>
                                navigate(`/models/${row.id}/edit`, {
                                    state: {
                                        collectionsFilters,
                                        modelsFilters: filters,
                                        referrer: newReferrer,
                                    },
                                }),
                            onArchive: (row) => {
                                setModelToArchive(row);
                            },
                            onDelete: (row) => setModelToDelete(row),
                        })}
                        rowSelectionModel={selectedModels}
                        onRowSelectionModelChange={(newSelection) => {
                            setSelectedModels(newSelection);
                        }}
                        filters={filters}
                        rowCount={rowCount}
                        onFiltersChange={(filters) => setFilters(filters as ModelsFilters)}
                        loading={isFetching}
                        localeText={{
                            noRowsLabel: isFetching
                                ? ''
                                : (currentTab === 'active' &&
                                        (numberOfSelectedFilters > 0 || filters?.search)) ||
                                    currentTab === 'archived'
                                  ? 'No models found.'
                                  : 'Create your first model.',
                            noResultsOverlayLabel: isFetching
                                ? ''
                                : (currentTab === 'active' &&
                                        (numberOfSelectedFilters > 0 || filters?.search)) ||
                                    currentTab === 'archived'
                                  ? 'No models found.'
                                  : 'Create your first model.',
                        }}
                    />
                </Grid>
            </Grid>
            <CustomDialog
                open={!!modelToArchive}
                onClose={() => setModelToArchive(null)}
                onConfirm={onArchiveModel}
                title={currentTab === 'active' ? 'Archive model' : 'Restore model'}
                content={
                    currentTab === 'active' ? (
                        <Typography variant="p1" textAlign="center">
                            Are you sure that you want to archive model{' '}
                            <Typography variant="p1SemiBold">{modelToArchive?.name}</Typography>?
                            From this action model won’t be available in marketplace anymore.
                        </Typography>
                    ) : (
                        <Typography variant="p1" textAlign="center">
                            Are you sure that you want to restore model{' '}
                            <Typography variant="p1SemiBold">{modelToArchive?.name}</Typography>?
                            From this action model will be available in marketplace again.
                        </Typography>
                    )
                }
            />
            <CustomDialog
                open={!!modelToDelete}
                onClose={() => setModelToDelete(null)}
                onConfirm={onDeleteModel}
                title="Delete model"
                content={
                    <Typography variant="p1" textAlign="center">
                        Are you sure that you want to delete model{' '}
                        <Typography variant="p1SemiBold">{modelToDelete?.name}</Typography>? From
                        this action model will be removed from Storage.
                    </Typography>
                }
            />
        </Box>
    );
};

export default ModelsView;
