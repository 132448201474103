import { useMemo, useRef, useState } from 'react';
import { Box, Button, Grid, Tab, Tabs, Typography, useMediaQuery, useTheme } from '@mui/material';

import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg';

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import CustomDataGrid from '../../components/CustomDataGrid/CustomDataGrid';
import CustomTextField from '../../components/CustomTextField/CustomTextField';
import CustomDialog from '../../components/CustomDialog/CustomDialog';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { CreditPackage } from '../../types/credits';

import { BaseFilters } from '../../types/table';
import { BASE_PAGE_SIZE_OPTIONS, calculateRowHeightAndPageSize } from '../../helpers/datagrid';
import { APIError } from '../../axios/axiosVeerlInstance';
import { ModelTabs } from '../Models/ModelsView';
import { CREDIT_PACKAGES_TABLE_COLUMNS } from '../../const/creditPackagesTableColumns';
import { deleteCreditPackage, getCreditPackages } from '../../api/creditPackage';
import CreditPackageCreator from './CreditPackageCreator';

const CreditPackagesView = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const theme = useTheme();
    const location = useLocation();
    const setToastState = useSetAtom(toastAtom);

    const [searchParams] = useSearchParams();
    const queryTab = searchParams.get('tab');

    const rowProperties = calculateRowHeightAndPageSize(210);
    const savedPageSize = localStorage.getItem('creditPackages.pageSize');

    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const { creditPackagesFilters } = location.state || {};
    const { referrer } = location.state || [];

    const [filters, setFilters] = useState<BaseFilters>(
        creditPackagesFilters || {
            search: '',
            sort: {
                field: 'dateUpdate',
                order: 'desc',
            },
            pageSize:
                savedPageSize &&
                +savedPageSize &&
                BASE_PAGE_SIZE_OPTIONS.concat(rowProperties.pageSize).includes(+savedPageSize)
                    ? +savedPageSize
                    : rowProperties.pageSize,
            page: 1,
        }
    );

    const [isCreatorOpen, setIsCreatorOpen] = useState<boolean>(false);
    const [creditPackageToDelete, setCreditPackageToDelete] = useState<CreditPackage | null>(null);
    const [editingCreditPackage, setEditingCreditPackage] = useState<CreditPackage | null>(null);

    const [currentTab, setCurrentTab] = useState<ModelTabs>((queryTab as ModelTabs) || 'active');

    const { data: creditPackagesResponse, isFetching } = useQuery(['creditPackages', filters], () =>
        getCreditPackages(filters)
    );

    const creditPackages = creditPackagesResponse?.creditPackages || [];
    const newReferrer = referrer?.length ? [...referrer, '/creditPackages'] : ['/creditPackages'];

    const deleteMutation = useMutation(deleteCreditPackage, {
        onSuccess: () => {
            setToastState({
                message: 'Credit package deleted successfully!',
                severity: 'success',
            });
            setCreditPackageToDelete(null);
            queryClient.invalidateQueries('creditPackages');
        },
        onError: (error: APIError) => {
            setToastState({
                message: error?.message,
                severity: 'error',
            });
        },
    });

    const onDeleteCreditPackage = () => {
        if (!creditPackageToDelete) return;

        deleteMutation.mutate(creditPackageToDelete.id);
    };

    const onCreditPackageCreatorClose = () => {
        setIsCreatorOpen(false);
        setEditingCreditPackage(null);
    };

    const rowCountRef = useRef(creditPackagesResponse?.totalResults || 0);

    const rowCount = useMemo(() => {
        if (creditPackagesResponse?.totalResults !== undefined) {
            rowCountRef.current = creditPackagesResponse.totalResults;
        }
        return rowCountRef.current;
    }, [creditPackagesResponse?.totalResults]);

    return (
        <>
            <CreditPackageCreator
                editingCreditPackage={editingCreditPackage}
                isOpen={isCreatorOpen || editingCreditPackage !== null}
                onClose={onCreditPackageCreatorClose}
            />
            <Box component="div" flexDirection="column">
                <Grid
                    item
                    xs={12}
                    sx={{
                        padding: '24px 20px',
                    }}>
                    <Grid container gap={isSmallScreen ? '16px' : 0}>
                        <Grid item xs={isSmallScreen ? 12 : 4}>
                            <CustomTextField
                                fullWidth
                                name="search"
                                label="Search"
                                size="small"
                                onChange={(e: any) =>
                                    setFilters({
                                        ...filters,
                                        search: e.target.value,
                                        page: 1,
                                    })
                                }
                            />
                        </Grid>
                        <Grid item xs={isSmallScreen ? 12 : 8}>
                            <Box
                                component="div"
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                }}>
                                <Button
                                    onClick={() => setIsCreatorOpen(true)}
                                    variant="contained"
                                    color="primary"
                                    endIcon={!isMediumScreen ? <PlusIcon /> : null}>
                                    {!isMediumScreen ? 'Add Credit Package' : <PlusIcon />}
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    gap="16px"
                    sx={{
                        padding: '16px 20px',
                    }}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            position: 'relative',
                        }}>
                        <CustomDataGrid
                            height="calc(100vh - 268px)"
                            disableColumnFilter
                            tableName="creditPackages"
                            rows={creditPackages}
                            onCellClick={(params) => {
                                if (params.field !== '__check__') {
                                    setEditingCreditPackage(params.row as CreditPackage);
                                }
                            }}
                            columns={CREDIT_PACKAGES_TABLE_COLUMNS({
                                onEdit: (row) => setEditingCreditPackage(row),
                                onDelete: (row) => setCreditPackageToDelete(row),
                            })}
                            rowCount={rowCount}
                            rowProperties={rowProperties}
                            filters={filters}
                            onFiltersChange={(filters) => {
                                setFilters({
                                    ...filters,
                                    sort: {
                                        field: filters.sort ? filters.sort.field : 'dateUpdate',
                                        order: filters.sort ? filters.sort.order : 'desc',
                                    },
                                } as BaseFilters);
                            }}
                            loading={isFetching}
                            localeText={{
                                noRowsLabel: isFetching
                                    ? ''
                                    : filters.search !== '' || currentTab === 'archived'
                                      ? 'No credit packages found.'
                                      : 'Create your first credit package.',
                                noResultsOverlayLabel: isFetching
                                    ? ''
                                    : filters.search !== '' || currentTab === 'archived'
                                      ? 'No credit packages found.'
                                      : 'Create your first credit package.',
                            }}
                        />
                    </Grid>
                </Grid>
                <CustomDialog
                    open={!!creditPackageToDelete}
                    onClose={() => setCreditPackageToDelete(null)}
                    onConfirm={onDeleteCreditPackage}
                    title="Delete credit package"
                    content={
                        <Typography variant="p1" textAlign="center">
                            Are you sure that you want to delete credit package{' '}
                            <Typography variant="p1SemiBold">
                                {creditPackageToDelete?.name}
                            </Typography>
                            ? From this action credit package will be permanently deleted.
                        </Typography>
                    }
                />
            </Box>
        </>
    );
};

export default CreditPackagesView;
