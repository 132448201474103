import { Box, Typography, Avatar, Theme, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Tier } from '../../types/model';
import parseDateFromToday from '../../helpers/date';

type UserAvatarProps = {
    firstName: string;
    dateLastActive?: string;
    tierSubscription?: Tier;
    credits: number;
    onSetCredits?: () => void;
};

const StyledAvatar = styled(Avatar)(({ theme }: { theme: Theme }) => ({
    backgroundColor: theme.palette.secondary.main,
}));

const UserAvatar = ({
    firstName,
    onSetCredits,
    tierSubscription,
    credits,
    dateLastActive,
}: UserAvatarProps) => {
    const theme = useTheme();
    return (
        <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <StyledAvatar
                sx={{
                    width: '64px',
                    height: '64px',
                    minWidth: '64px',
                    minHeight: '64px',
                    borderRadius: '100%',
                    backgroundColor: theme.palette.accentMagenta,
                }}>
                {firstName[0].toUpperCase()}
            </StyledAvatar>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                }}>
                <Typography variant="p1SemiBold">{firstName}</Typography>
                <Typography
                    marginTop="4px"
                    variant="p2SemiBold"
                    color={!tierSubscription ? theme.palette.black54 : theme.palette.accentMagenta}>
                    {tierSubscription ? `Tier ${tierSubscription}` : 'No subscription'}
                </Typography>
                <Box
                    component="div"
                    sx={{ display: 'flex', alignItems: 'center', gap: '2px', margin: '2px 0' }}>
                    <Typography variant="p2SemiBold">{'Credits: '}</Typography>
                    <Typography variant="p2">{credits || 0}</Typography>
                    <Typography
                        fontSize="0.75rem"
                        color={theme.palette.accentVividBlue}
                        marginLeft="4px"
                        lineHeight="100%"
                        fontWeight="600"
                        sx={{
                            cursor: 'pointer',
                        }}
                        onClick={onSetCredits}>
                        Set credits
                    </Typography>
                </Box>
                {dateLastActive ? (
                    <Box component="div" sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                        <Typography variant="p2SemiBold">{'Last active: '}</Typography>
                        <Typography variant="p2">{parseDateFromToday(dateLastActive)}</Typography>
                    </Box>
                ) : null}
            </Box>
        </Box>
    );
};

export default UserAvatar;
