import { GridColDef } from '@mui/x-data-grid';

import { Box, Chip, Tooltip, Typography } from '@mui/material';

import { ReactComponent as EditIcon } from '../assets/icons/settings.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import CustomChip from '../components/CustomChip/CustomChip';
import { CreditPackage } from '../types/credits';
import { format } from 'date-fns';

type CreditPackagesCallbacks = {
    onEdit: (row: CreditPackage) => void;
    onDelete: (row: CreditPackage) => void;
};

export const CREDIT_PACKAGES_TABLE_COLUMNS = ({
    onEdit,
    onDelete,
}: CreditPackagesCallbacks): GridColDef<CreditPackage>[] => [
    {
        field: 'thumbnail',
        headerName: '',
        display: 'flex',
        sortable: false,
        width: 80,
        renderCell: (params) => (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                    width: '64x',
                    height: '64px',
                    minWidth: '64px',
                    minHeight: '64px',
                    borderRadius: 8,
                    backgroundImage: params.value ? `url(${params.value})` : 'none',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: params.value ? 'transparent' : 'white',
                }}
            />
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        display: 'flex',
        flex: 1,
        width: 260,
        resizable: true,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.value as string}>
                    <Typography variant="p2SemiBold">{params.value}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        minWidth: 260,
        display: 'flex',
        flex: 1,
        resizable: true,
        sortComparator: (v1, v2) => v1[0].localeCompare(v2[0]),
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        alignItems: 'center',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                    }}>
                    <Typography
                        variant="body2"
                        sx={{
                            margin: 'auto',
                        }}>
                        {params.value}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'amount',
        headerName: 'Amount',
        display: 'flex',
        width: 160,
        renderCell: (params) => {
            return <CustomChip label={`${params.value || 0} credits`} variant="primaryOutlined" />;
        },
    },
    {
        field: 'price',
        headerName: 'Price',
        minWidth: 120,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    style={{ alignItems: 'center', height: '100%' }}>
                    <Chip
                        label={params.value ? `${params.value}$` : 'Free'}
                        variant={params.value ? 'primary' : 'primaryOutlined'}
                    />
                    {params.row.discount?.active && (
                        <CustomChip
                            tooltip={
                                params.row.discount.dateStart && params.row.discount.dateEnd
                                    ? `${format(new Date(params.row.discount.dateStart), 'dd/MM/yyyy, hh:mm a')} - ${format(new Date(params.row.discount.dateEnd), 'dd/MM/yyyy, hh:mm a')}`
                                    : ''
                            }
                            label={`-${params.row.discount.value}%`}
                            variant="primary"
                            sx={{ backgroundColor: '#06BE7E' }}
                        />
                    )}
                </Box>
            );
        },
    },
    {
        field: 'actions',
        headerName: '',
        headerClassName: 'stickyColumnHeader',
        display: 'flex',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        pinnable: true,
        width: 90,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    <Tooltip title="Edit">
                        <EditIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onEdit(params.row);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title="Delete">
                        <DeleteIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onDelete(params.row);
                            }}
                        />
                    </Tooltip>
                </Box>
            );
        },
    },
];
