import * as yup from 'yup';

export const AssistantValidationSchema = yup.object({
    name: yup
        .string()
        .max(128, 'Name cannot exceed 128 characters.')
        .when('active', {
            is: true,
            then: (schema) => schema.required('Name is required when active is enabled.'),
            otherwise: (schema) => schema.notRequired(),
        }),
    description: yup.string().max(2048, 'Description cannot exceed 2048 characters.').notRequired(),
    prompt: yup
        .string()
        .when('active', {
            is: true,
            then: (schema) => schema.required('Prompt is required when active is enabled.'),
            otherwise: (schema) => schema.notRequired(),
        }),
    welcomeMessage: yup
        .string()
        .max(256, 'Welcome message cannot exceed 256 characters.')
        .notRequired(),
    avatar: yup.string().optional(),
    active: yup.boolean().optional(),
    voice: yup.object().when('active', {
        is: true,
        then: (schema) => yup.string().required('Voice is required.'),
        otherwise: (schema) => yup.string().optional(),
    }),
    reference: yup.object().when('active', {
        is: true,
        then: (schema) =>
            yup.object({
                asset: yup.string().optional(),
                modelID: yup.string().required('Model is required when active is enabled.'),
            }),
        otherwise: (schema) =>
            yup.object({
                asset: yup.string().optional(),
                modelID: yup.string(),
            }),
    }),
});
