import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Box, Tooltip, Typography } from '@mui/material';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { Asset } from '../types/assets';

type UserAssetsColumnsCallbacks = {
    onDelete: (asset: Asset) => void;
};

export const USER_ASSETS_COLUMNS = ({
    onDelete,
}: UserAssetsColumnsCallbacks): GridColDef<Asset>[] => [
    {
        field: 'model.name',
        headerName: 'Asset name',
        display: 'flex',
        minWidth: 110,
        flex: 1,
        sortable: true, 
        valueGetter: (value: string, row: Asset) => {
            return row.reference?.collection?.name || row.reference?.model?.name;
        },
        sortComparator: (v1, v2) => {
            return v1.localeCompare(v2);
        },
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={
                        params.value
                    }>
                    <Typography variant="p2">
                        {params.value}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'type',
        headerName: 'Asset type',
        display: 'flex',
        minWidth: 220,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title="Model">
                    <Typography variant="p2">
                        {params.row.reference?.collection ? 'Collection' : 'Model'}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'dateAdd',
        headerName: 'Purchase date',
        display: 'flex',
        width: 220,
        valueGetter: (value: string) => {
            const formattedDate = format(value, 'dd-MM-yyyy HH:mm');
            return formattedDate;
        },
    },
    {
        field: 'actions',
        headerName: '',
        headerClassName: 'stickyColumnHeader',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        width: 60,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%">
                    {
                        <Tooltip title="Delete">
                            <DeleteIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDelete(params.row);
                                }}
                            />
                        </Tooltip>
                    }
                </Box>
            );
        },
    },
];
