import { Discount } from './model';
import { BaseFilters } from './table';

export type CreditPackage = {
    id: string;
    dateAdd: string;
    dateUpdate: string;
    name: string;
    description: string;
    amount: number;
    price: number;
    discount: Discount;
    thumbnail: string;
};

export type CreditPackageFormData = {
    name: string;
    description: string;
    amount: number | null;
    price: number | null;
    discount: Discount;
    thumbnail: string;
};

export const INITIAL_CREDIT_PACKAGE_FORM_DATA: CreditPackageFormData = {
    name: '',
    description: '',
    amount: null,
    price: null,
    discount: {
        active: false,
        value: null,
        dateStart: '',
        dateEnd: '',
    },
    thumbnail: '',
};
