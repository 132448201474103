import { useState } from 'react';
import { TextField, Box, Typography, useTheme } from '@mui/material';
import CustomDialog from '../CustomDialog/CustomDialog';
import { useMutation, useQueryClient } from 'react-query';
import { useSetAtom } from 'jotai';
import { toastAtom } from '../../atoms/toastAtom';
import { addUserCredits } from '../../api/user';
import CustomTextField from '../CustomTextField/CustomTextField';

type AddUserCreditsDialogProps = {
    open: boolean;
    onClose: () => void;
    currentCredits: number;
    userId: string;
};

const AddUserCreditsDialog = ({
    open,
    onClose,
    currentCredits,
    userId,
}: AddUserCreditsDialogProps) => {
    const theme = useTheme();
    const queryClient = useQueryClient();
    const setToastState = useSetAtom(toastAtom);

    const [credits, setCredits] = useState<string>();
    const [error, setError] = useState<string | null>(null);

    const mutation = useMutation(
        ({ userId, credits }: { userId: string; credits: number }) =>
            addUserCredits(userId, credits),
        {
            onSuccess: () => {
                setToastState({
                    message: 'Credits updated successfully!',
                    severity: 'success',
                });
                queryClient.invalidateQueries(['user', userId]);
                setCredits(undefined);
                onClose();
            },
            onError: (error: any) => {
                setToastState({
                    message: error?.message,
                    severity: 'error',
                });
            },
        }
    );

    const handleConfirm = () => {
        setError(null);

        if (
            !credits ||
            credits === undefined ||
            credits === null ||
            isNaN(Number(credits)) ||
            credits === '-'
        ) {
            setError('Please enter a valid number');
            return;
        }

        if (Number(credits) > 2147483647) {
            setError('Please enter a number less than 2147483647');
            return;
        }

        mutation.mutate({ userId, credits: Number(credits) });
    };

    return (
        <CustomDialog
            open={open}
            onClose={onClose}
            onConfirm={handleConfirm}
            title="Add / remove user credits"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            isLoading={mutation.isLoading}
            content={
                <Box
                    component="div"
                    display="flex"
                    flexDirection="column"
                    gap="16px"
                    minWidth="320px">
                    <Typography variant="p2" textAlign="left" color={theme.palette.black54}>
                        You can add or remove user credits by entering the amount (positive or
                        negative, like -10) below.
                    </Typography>
                    <CustomTextField
                        label="Credits"
                        type="number"
                        value={credits}
                        onChange={(e) => setCredits(e.target.value)}
                        fullWidth
                        error={!!error}
                        helperText={error}
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                        }}
                    />
                </Box>
            }
        />
    );
};

export default AddUserCreditsDialog;
