import { Tier } from './model';

export type User = {
    id: string;
    authMethod: 'email' | 'oauth';
    email: string;
    firstName: string;
    declarationOfAge18: boolean;
    marketingAgreement: boolean;
    emailConfirmed: boolean;
    authToken: string;
    admin: boolean;
    superUser: boolean;
    dateLastActive: string;
    tier?: Tier;
    credits: number;
};

export type SignInData = {
    email: string;
    password: string;
};

export const INITIAL_SIGN_IN_DATA: SignInData = {
    email: '',
    password: '',
};

export type GetUsersReponse = {
    users: User[];
    totalResults: number;
};

export type UserPersonalData = {
    firstName: string;
    email: string;
    emailConfirmed: boolean;
    superUser: boolean;
};
