import { GridColDef } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { Box, Theme, Tooltip, Typography } from '@mui/material';
import CustomChip from '../components/CustomChip/CustomChip';
import { ReactComponent as DownloadIcon } from '../assets/icons/download.svg';
import { Payment } from '../types/payments';

type PaymentHistoryColumnsCallbacks = {
    theme: Theme;
    onDownloadInvoice: (paymentID: string, url: string) => void;
};

export const PAYMENT_HISTORY_TABLE_COLUMNS = ({
    theme,
    onDownloadInvoice,
}: PaymentHistoryColumnsCallbacks): GridColDef<Payment>[] => [
    {
        field: 'order.id',
        headerName: 'Order ID',
        width: 240,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box component="div" title={params.row.order.id as string}>
                    <Typography variant="p2SemiBold">{params.row.order.id || '-'}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'order.type',
        headerName: 'Order type',
        width: 200,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.row.order.type as string}>
                    <Typography variant="p2">
                        {params.row.order.type === 'purchase_model'
                            ? 'Purchase model'
                            : params.row.order.type === 'purchase_credit_package'
                              ? 'Purchase credits'
                              : params.row.order.type === 'subscribe_collection'
                                ? 'Subscribe collection'
                                : 'Subscribe Tier'}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'dateAdd',
        headerName: 'Date',
        width: 200,
        display: 'flex',
        valueGetter: (value: string) => {
            const formattedDate = value ? format(value, 'dd-MM-yyyy HH:mm') : '';
            return formattedDate;
        },
    },
    {
        field: 'order.name',
        headerName: 'Asset name',
        minWidth: 280,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.row.order.name as string}>
                    <Typography variant="p2">
                        {params?.row?.order?.type === 'purchase_credit_package'
                            ? params?.row?.order?.name
                            : (params.row?.order?.reference?.model?.name ??
                              params.row?.order?.reference?.collection?.name ??
                              (params.row?.order?.reference?.tier
                                  ? `Tier ${params.row?.order?.reference?.tier}`
                                  : ''))}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'order.amount',
        headerName: 'Amount',
        minWidth: 120,
        display: 'flex',
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.row.order.amount + ''}>
                    <Typography variant="p2">
                        {params.row.order.amount + ' ' + params.row.order.currency}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'order.price.discountValue',
        headerName: 'Discount',
        minWidth: 120,
        display: 'flex',
        renderCell: (params) => {
            return (
                <>
                    {params.row.order?.price?.discountValue ? (
                        <CustomChip
                            label={'-' + params.row.order?.price?.discountValue + '%'}
                            variant="primaryOutlined"
                        />
                    ) : null}
                </>
            );
        },
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 120,
        display: 'flex',
        renderCell: (params) => {
            return (
                <CustomChip
                    sx={{
                        background:
                            params.row.status === 'succeded'
                                ? theme.palette.confirmationGreen
                                : params.row.status === 'pending'
                                  ? theme.palette.warningYellow
                                  : theme.palette.accentMagenta,
                    }}
                    label={params.value[0].toUpperCase() + params.value.slice(1)}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },

    {
        field: 'actions',
        headerName: '',
        headerClassName: 'stickyColumnHeader',
        disableColumnMenu: true,
        sortable: false,
        display: 'flex',
        cellClassName: 'stickyColumnCell',
        width: 60,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    {params.row.status === 'succeded' && params.row.invoice?.pdfURL ? (
                        <Tooltip title="Download invoice">
                            <DownloadIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDownloadInvoice(
                                        params.row.order.id,
                                        params.row.invoice.pdfURL
                                    );
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </Box>
            );
        },
    },
];
