import { Model } from './model';

export type Assistant = {
    id: string;
    dateAdd: string;
    dateUpdate: string;
    description: string;
    name: string;
    prompt: string;
    welcomeMessage?: string;
    voice: string;
    avatar?: string;
    active: boolean;
    reference: {
        asset?: string;
        modelID?: string;
        model?: Model;
    };
};

export type AssistantFormData = {
    name: string;
    description: string;
    prompt: string;
    welcomeMessage?: string;
    avatar?: string;
    active: boolean;
    voice: string;
    reference: {
        asset?: string;
        modelID?: string;
    };
};

export type AssistantTTSVoices = {
    [key: string]: string;
};

export const ASSISTANT_INITIAL_FORM_DATA: AssistantFormData = {
    name: '',
    active: false,
    description: '',
    prompt: '',
    welcomeMessage: '',
    avatar: '',
    reference: {
        modelID: '',
        asset: '',
    },
    voice: '',
};
