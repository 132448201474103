import { GridColDef } from '@mui/x-data-grid';

import { Box, Tooltip, Typography } from '@mui/material';
import { Collection } from '../types/collection';

import { ReactComponent as EditIcon } from '../assets/icons/settings.svg';
import { ReactComponent as DeleteIcon } from '../assets/icons/delete.svg';
import { ReactComponent as ClockIcon } from '../assets/icons/clock.svg';
import { ReactComponent as ArrowBackOutlinedIcon } from '../assets/icons/arrow-back-outlined.svg';
import CustomChip from '../components/CustomChip/CustomChip';

type CollectionsColumnsCallbacks = {
    currentTab: 'active' | 'archived';
    onEdit: (row: Collection) => void;
    onDelete: (row: Collection) => void;
    onArchive: (row: Collection) => void;
    onOpenModels: (row: Collection) => void;
};

export const COLLECTIONS_TABLE_COLUMNS = ({
    currentTab,
    onEdit,
    onDelete,
    onArchive,
    onOpenModels,
}: CollectionsColumnsCallbacks): GridColDef<Collection>[] => [
    {
        field: 'thumbnail',
        headerName: '',
        display: 'flex',
        sortable: false,
        width: 80,
        renderCell: (params) => (
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{
                    width: '64x',
                    height: '64px',
                    minWidth: '64px',
                    minHeight: '64px',
                    borderRadius: 8,
                    backgroundImage: params.value ? `url(${params.value})` : 'none',
                    backgroundSize: 'contain',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundColor: params.value ? 'transparent' : 'white',
                }}
            />
        ),
    },
    {
        field: 'name',
        headerName: 'Name',
        display: 'flex',
        width: 260,
        resizable: true,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        maxWidth: '100%',
                    }}
                    title={params.value as string}>
                    <Typography variant="p2SemiBold">{params.value}</Typography>
                </Box>
            );
        },
    },
    {
        field: 'description',
        headerName: 'Description',
        minWidth: 260,
        display: 'flex',
        resizable: true,
        sortComparator: (v1, v2) => v1[0].localeCompare(v2[0]),
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    sx={{
                        alignItems: 'center',
                        display: '-webkit-box',
                        WebkitBoxOrient: 'vertical',
                        WebkitLineClamp: 3,
                        overflow: 'hidden',
                        whiteSpace: 'normal',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                    }}>
                    <Typography
                        variant="body2"
                        sx={{
                            margin: 'auto',
                        }}>
                        {params.value}
                    </Typography>
                </Box>
            );
        },
    },
    {
        field: 'isPublic',
        headerName: 'Public',
        display: 'flex',
        minWidth: 130,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'assistant',
        headerName: 'Assistant',
        display: 'flex',
        minWidth: 130,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.row?.assistant?.active ? 'Yes' : 'No'}
                    variant={params.row?.assistant?.active ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'showOnLandingPage',
        headerName: 'Show on LP',
        display: 'flex',
        minWidth: 130,
        renderCell: (params) => {
            return (
                <CustomChip
                    label={params.value ? 'Yes' : 'No'}
                    variant={params.value ? 'primary' : 'primaryOutlined'}
                />
            );
        },
    },
    {
        field: 'price',
        headerName: 'Subscription price',
        display: 'flex',
        width: 180,
        renderCell: (params) => {
            return (
                <Typography variant="p2">
                    {params.value} {params.value ? '$' : ''}
                </Typography>
            );
        },
    },
    {
        field: 'likes.count',
        headerName: 'Likes',
        display: 'flex',
        width: 140,
        renderCell: (params) => {
            return <CustomChip label={params.row.likes.count} variant="secondary" />;
        },
    },
    {
        field: 'sortOrder',
        display: 'flex',
        headerName: 'Sort order',
        width: 140,
        renderCell: (params) => {
            return <CustomChip label={params.row.sortOrder} variant="secondary" />;
        },
    },
    {
        field: 'modelsCount.active',
        headerName: 'Active models',
        display: 'flex',
        width: 140,
        renderCell: (params) => {
            return <Typography variant="p2">{params.row.modelsCount.active}</Typography>;
        },
    },
    {
        field: 'modelsCount.archived',
        headerName: 'Archived models',
        display: 'flex',
        width: 140,
        renderCell: (params) => {
            return <Typography variant="p2">{params.row.modelsCount.archived}</Typography>;
        },
    },
    {
        field: 'modelsCount.total',
        headerName: 'Total',
        display: 'flex',
        width: 120,
        renderCell: (params) => {
            return <Typography variant="p2">{params.row.modelsCount.total}</Typography>;
        },
    },
    {
        field: 'actions',
        headerName: '',
        headerClassName: 'stickyColumnHeader',
        display: 'flex',
        disableColumnMenu: true,
        sortable: false,
        cellClassName: 'stickyColumnCell',
        pinnable: true,
        width: 130,
        renderCell: (params) => {
            return (
                <Box
                    component="div"
                    display="flex"
                    gap="8px"
                    justifyContent="center"
                    alignItems="center"
                    width="100%">
                    {currentTab === 'active' ? (
                        <Tooltip title="Check models">
                            <ArrowBackOutlinedIcon
                                style={{
                                    transform: 'rotate(180deg)',
                                }}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onOpenModels(params.row);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                    <Tooltip title="Edit">
                        <EditIcon
                            onClick={(event) => {
                                event.stopPropagation();
                                onEdit(params.row);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={currentTab === 'active' ? 'Archive' : 'Restore'}>
                        {currentTab === 'active' ? (
                            <ClockIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onArchive(params.row);
                                }}
                            />
                        ) : (
                            <ArrowBackOutlinedIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onArchive(params.row);
                                }}
                            />
                        )}
                    </Tooltip>
                    {params.row.canBeDeleted ? (
                        <Tooltip title="Delete">
                            <DeleteIcon
                                onClick={(event) => {
                                    event.stopPropagation();
                                    onDelete(params.row);
                                }}
                            />
                        </Tooltip>
                    ) : null}
                </Box>
            );
        },
    },
];
