import { Box, FormControlLabel, Grid, Switch, Typography, useTheme } from '@mui/material';
import FormikSwitch from '../../Formik/FormikSwitch';
import { useField } from 'formik';
import { Discount } from '../../../types/model';
import { useEffect, useState } from 'react';
import FormikTextField from '../../Formik/FormikTextField';

type ModelFreeProps = {
    isPreviewMode?: boolean;
};

const ModelFree = ({ isPreviewMode }: ModelFreeProps) => {
    const [isFreeForGuestsField, ,] = useField<boolean>('isFreeForGuests');
    const [isFreeForUsersField, ,] = useField<boolean>('isFreeForUsers');
    const [stockField, , stockHelpers] = useField<number | undefined>('stock');
    const [stockAvailableField, ,] = useField<number | undefined>('stockAvailable');

    const [, , priceHelpers] = useField<number | null>('price');
    const [, , discountHelpers] = useField<Discount>('discount');

    useEffect(() => {
        if (isFreeForGuestsField.value === true || isFreeForUsersField.value === true) {
            priceHelpers.setValue(null);
            discountHelpers.setValue({
                active: false,
                value: null,
                dateStart: '',
                dateEnd: '',
            });
        }
    }, [isFreeForGuestsField.value, isFreeForUsersField.value]);

    return (
        <>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<FormikSwitch name="isFreeForGuests" disabled={isPreviewMode} />}
                    label={<Typography variant="p2">Is free for guests?</Typography>}
                    labelPlacement="start"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: 0,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<FormikSwitch name="isFreeForUsers" disabled={isPreviewMode} />}
                    label={<Typography variant="p2">Is free for users?</Typography>}
                    labelPlacement="start"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: 0,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Box component="div"></Box>}
                    label={<Typography variant="p2SemiBold">Stock</Typography>}
                    labelPlacement="start"
                    sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginLeft: 0,
                    }}
                />
                <Box
                    component="div"
                    mt="16px"
                    sx={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                    {stockAvailableField.value ? (
                        <Typography variant="p2">
                            Stock available: {stockAvailableField.value}
                        </Typography>
                    ) : null}
                    <FormikTextField
                        fullWidth
                        type="number"
                        disabled={isPreviewMode}
                        label="Max stock"
                        name="stock"
                        variant="outlined"
                    />
                </Box>
            </Grid>
        </>
    );
};

export default ModelFree;
