import { TextField, TextFieldProps, styled } from '@mui/material';
import { useField } from 'formik';

const StyledTextField = styled(TextField)(({ theme, error, multiline }) => ({
    ...(multiline
        ? {
              '& .MuiFilledInput-root': {
                  backgroundColor: theme.palette.backgroundLighter,
                  border: '1px solid ' + (error ? theme.palette.alert : theme.palette.borders),
                  borderRadius: '4px',
                  '&.Mui-focused': {
                      border: `1px solid ${theme.palette.black84}`,
                  },
                  '&:after': {
                      borderBottomColor: 'transparent',
                  },
              },
          }
        : {
              '& .MuiInputBase-input': {
                  backgroundColor: theme.palette.backgroundLighter,
                  border: '1px solid ' + (error ? theme.palette.alert : theme.palette.borders),
                  borderRadius: '4px',

                  '&:focus:not(:placeholder-shown)': {
                      border: `1px solid ${theme.palette.black84}`,
                  },

                  '&:focus': {
                      border: `1px solid ${theme.palette.black54}`,
                  },
              },
          }),
    '& .MuiFilledInput-underline:after': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:before': {
        borderBottom: 'none',
    },
    '& .MuiFilledInput-underline:hover:before': {
        borderBottom: 'none',
    },
    '& input[type=number]': {
        '-moz-appearance': 'textfield',
        '&::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '&::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
}));

type FormikTextFieldProps = TextFieldProps & {
    name: string;
};

const FormikTextField = ({ name, disabled, InputProps, ...props }: FormikTextFieldProps) => {
    const [field, meta] = useField(name);
    console.log(field)
    return (
        <StyledTextField
            {...field}
            {...props}
            size="small"
            disabled={disabled}
            variant="filled"
            error={Boolean(meta.error)}
            value={field.value !== undefined ? (field.value === null ? '' : field.value) : ''}
            helperText={meta.error}
            InputProps={{
                ...InputProps,
                ...(disabled ? { disableUnderline: true } : {}),
                inputProps: {
                    min: 0, // Prevent negative numbers
                    ...InputProps?.inputProps,
                },
            }}
        />
    );
};

export default FormikTextField;
